import { MvJourney } from '@/lib/strapi-types/MvJourney';
import { DevBackEndpoints } from '@/lib/types/enums/backEndpoints.enum';
import { Strapi4ResponseMany } from '@nuxtjs/strapi/dist/runtime/types';
import { stringify } from 'qs';

interface PopulateObject {
  [key: string]:
    | string
    | boolean
    | PopulateObject
    | {
        sort?: string[];
        filters?: Record<string, unknown>;
        populate?: PopulateObject;
        fields?: string[];
      };
}

interface UseFindJourneysProps {
  filters?: Record<string, unknown>;
  populate?: string[] | PopulateObject;
  fields?: string[];
}

/**
 * Processes the populate parameter and returns the appropriate value.
 * If the populate parameter is an array, it merges it with the defaultPopulate array.
 * If the populate parameter is an object, it merges it with the defaultPopulate object.
 * If the populate parameter is not provided or is of an invalid type, it returns the defaultPopulate array.
 * @param populate - The populate parameter to process.
 * @returns The processed populate value.
 */
const processPopulate = (populate?: string[] | PopulateObject) => {
  const defaultPopulate = ['places', 'tags.icon', 'continents', 'countries', 'basePriceHeadline'];

  if (Array.isArray(populate)) {
    // If populate is an array, merge with defaultPopulate
    return [...defaultPopulate, ...populate];
  } else if (typeof populate === 'object' && populate !== null) {
    // If populate is an object, convert defaultPopulate into an object and merge
    return {
      ...defaultPopulate.reduce((acc, key) => ({ ...acc, [key]: true }), {}),
      ...populate
    };
  } else {
    // If populate is invalid or not provided, return the defaultPopulate array
    return defaultPopulate;
  }
};

export async function useFindJourneys({
  filters = {},
  populate,
  fields = []
}: UseFindJourneysProps = {}) {
  const filtersStore = useFiltersStore();
  const { journeysFilter, journeysFilterParams } = storeToRefs(filtersStore);

  const journeys = ref<MvJourney[]>([]);
  const pending = ref<boolean>(false);

  // Process the populate value (array or object) before using it in the query
  const allPopulate = processPopulate(populate);

  const defaultSort = ['weight:desc'];
  const defaultFilter = { price: { $gt: 0 } };

  pending.value = false;

  const parsedQuery = stringify(
    {
      populate: allPopulate, // Use the processed populate value here
      filters: { ...defaultFilter, ...journeysFilter.value, ...filters },
      sort: [...defaultSort, journeysFilterParams.value.sort ?? ''],
      pagination: {
        start: 0,
        limit: 32,
        withCount: true
      },
      fields
    },
    { arrayFormat: 'brackets' }
  );

  const { data } = await useFetchWithHead<Strapi4ResponseMany<MvJourney['attributes']>>(
    `${useCompleteUrl(DevBackEndpoints.JOURNEYS)}?${parsedQuery}`
  );
  journeys.value = data.value?.data ?? [];
  pending.value = false;

  return { journeys, pending };
}
