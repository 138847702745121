<template>
  <div
    class="journey-card"
    :style="`background: url('${useRuntimeConfig().public.marcovasco.twicpicsPlanetveoUrl}${
      journey.attributes.titleImage
    }?twic=v1/output=webp/resize=${breakpoints.isMobile ? 358 : 250}x${breakpoints.isMobile ? 301 : 206}') no-repeat center / cover`"
    @mouseover="isHovering = true"
    @mouseleave="isHovering = false"
  >
    <div class="background-gradient"></div>
    <div class="inner">
      <div class="top">
        <div class="left">
          <div v-for="(tag, i) of journey.attributes.tags?.data" :key="i">
            <div
              v-if="tag.attributes.icon?.data"
              class="tag-icon"
              :style="{ backgroundColor: tag.attributes.backgroundColor }"
            >
              <NuxtImg
                v-if="tag.attributes?.icon?.data"
                provider="twicpics"
                :alt="tag.attributes.name"
                :src="tag.attributes?.icon?.data?.attributes.url"
                class="icon"
                loading="lazy"
              />
            </div>
            <div
              v-else
              class="tag-name"
              :style="{
                color: tag.attributes.textColor,
                backgroundColor: tag.attributes.backgroundColor
              }"
            >
              {{ tag.attributes.name }}
            </div>
          </div>
        </div>
        <!--<div class="right">
          <Icon :name="like ? 'heart-active' : 'heart-inactive'" @click="like = !like" />
        </div>-->
      </div>

      <NuxtLink
        class="infos"
        :to="`/${continentSlug}/${countrySlug}/tours/${journey.attributes.slug}/`"
      >
        <h3 class="title">{{ journey.attributes.title }}</h3>
        <div class="price">
          <div class="label">À partir de</div>
          <div class="value">
            {{ journey.attributes.price?.toLocaleString() }}€
            {{
              getBasePriceHeadlineValues(journey.attributes.basePriceHeadline ?? 0, true)['stars']
            }}
          </div>
        </div>
      </NuxtLink>
    </div>

    <NuxtLink
      class="bottom"
      :to="`/${continentSlug}/${countrySlug}/tours/${journey.attributes.slug}/`"
    >
      <div class="durations">
        <div v-show="!(journey.attributes.days <= 0)" class="days">
          {{ journey.attributes.days }} {{ journey.attributes.days === 1 ? 'Jour' : 'Jours' }}
        </div>
        <div v-show="!(journey.attributes.nights <= 0)" class="nights">
          {{ journey.attributes.nights }} {{ journey.attributes.nights === 1 ? 'Nuit' : 'Nuits' }}
        </div>
        <div
          v-if="journey.attributes.numberOfReviews && journey.attributes.numberOfReviews > 0"
          class="reviews-wrapper"
        >
          <div class="point" />
          <div class="reviews">{{ journey.attributes.numberOfReviews }} avis</div>
        </div>
      </div>
      <div class="locations-container">
        <Icon name="map-pin" width="9px" height="11px" />
        <div class="locations">{{ placesName }}</div>
      </div>
    </NuxtLink>
    <NuxtLink :href="`/${continentSlug}/${countrySlug}/tours/${journey.attributes.slug}/`">
      <div :class="isHovering ? 'see-more-up' : 'see-more-down'">En savoir plus</div></NuxtLink
    >
  </div>
</template>

<script lang="ts" setup>
import { MvJourney } from '@/lib/strapi-types/MvJourney';
import { getBasePriceHeadlineValues } from '@/lib/journey';

const { journey } = defineProps<{
  journey: MvJourney;
}>();

const breakpoints = useBreakpoints();

// const like = $ref(false);
const isHovering = $ref(false);
const countrySlug = journey.attributes.countries?.data[0]?.attributes.slug;
const continentSlug = journey.attributes.continents?.data[0]?.attributes.slug;

const placesName = journey.attributes.places?.data.map(place => place.attributes.label).join(' - ');
</script>

<style lang="scss" scoped>
@use '$/breakpoints.scss';
@use '$/colors.scss';
@use '$/text-ellipsis.scss';

.journey-card {
  $card-width: 248px;
  $bottom-height: 54px;
  $card-mobile-width: 280px;
  $card-mobile-height: 375px;

  position: relative;

  overflow: hidden;
  flex-direction: column;
  flex-shrink: 0;

  width: $card-width;
  height: 280px;

  color: white;

  background-size: cover;
  border-radius: 8px;

  @include breakpoints.mobile() {
    width: $card-mobile-width;
    height: $card-mobile-height;
  }

  .inner {
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;

    padding: 11px 16px;

    background: linear-gradient(
      180deg,
      rgb(0 0 0 / 0%) 36.32%,
      rgb(0 0 0 / 45%) 71.32%,
      rgb(0 0 0 / 90%) 121.93%
    );

    .top {
      overflow: auto;

      .left {
        flex: 1;
        flex-wrap: wrap;
        gap: 6px;
        align-items: center;

        .tag-name {
          padding: 2px 24px;
          font-size: 14px;
          line-height: 17px;
          border-radius: 4px;

          @include breakpoints.mobile() {
            padding: 5px 22px;
          }
        }

        .tag-icon {
          $size: 18px;

          align-items: center;
          justify-content: center;

          width: $size;
          height: $size;

          border-radius: 50%;

          @include breakpoints.mobile() {
            width: 28px;
            height: 28px;

            .icon {
              width: 17px;
              height: 17px;
            }
          }

          .icon {
            width: 12px;
            height: 12px;
          }
        }
      }

      /*   .right {
        cursor: pointer;
        align-items: start;
        margin-left: 6px;

        @include breakpoints.mobile() {
          &:deep(svg) {
            width: 28px;
            height: 28px;
          }
        }

        &:deep(svg) {
          width: 22px;
          height: 20px;
        }
      } */
    }

    .infos {
      flex-direction: column;
      gap: 4px;

      .title {
        @include text-ellipsis.multi-line(2);

        font-size: 24px;
        font-weight: 900;
        line-height: 29px;
        color: white;
      }

      .price {
        flex-direction: column;
        gap: -2px;
        font-weight: 900;
        color: colors.$primary-yellow;

        .label {
          font-size: 12px;
          line-height: 14px;
        }

        .value {
          font-size: 24px;
          line-height: 29px;
        }
      }
    }
  }

  .bottom {
    flex-direction: column;
    gap: 2px;

    height: $bottom-height;
    padding: 10px 16px;

    background-color: colors.$primary-black;

    .durations {
      gap: 8px;
      align-items: center;

      font-size: 12px;
      font-weight: 700;
      line-height: 14px;

      .reviews-wrapper {
        display: flex;
        gap: 6px;
        align-items: center;
      }

      .days,
      .reviews {
        font-size: 12px;
        font-weight: bolder;
      }

      .days,
      .nights,
      .reviews {
        color: white;
      }

      .point {
        $size: 2px;

        width: $size;
        height: $size;
        background-color: white;
        border-radius: 50%;
      }
    }

    .locations-container {
      gap: 6px;
      align-items: center;
      width: 90%;
      margin-top: 2px;

      .locations {
        @include text-ellipsis.single-line();

        font-size: 12px;
        font-weight: 500;
        line-height: 17px;
        color: white;
      }
    }
  }

  .see-more-up,
  .see-more-down {
    cursor: pointer;

    position: absolute;

    gap: 6px;
    justify-content: center;

    width: 100%;
    height: $bottom-height;
    padding: 12px 16px;

    font-size: 16px;
    font-weight: 600;
    color: colors.$primary-black;

    background-color: colors.$primary-yellow;
    border-radius: 0 0 8px 8px;

    @include breakpoints.mobile() {
      display: none;
    }
  }

  .see-more-down {
    bottom: -$bottom-height;
    transition: all 0.25s ease-in-out;

    @include breakpoints.mobile() {
      display: none;
    }
  }

  .see-more-up {
    bottom: 0;
    transition: all 0.25s ease-in-out;

    @include breakpoints.mobile() {
      display: none;
    }
  }
}
</style>
